import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import store from '../../../../../store';
export default {
  name: 'shortClose',
  components: {
    DatePicker
  },
  props: ['nfaHdrId'],
  data() {
    return {
      loader: false,
      editMode: true,
      unsubscribe: null,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      shortCloseDate: commonHelper.formattedDate(new Date()),
      reason: null,
      closedBy: {
        value: null,
        text: this.getUserName()
      }
    };
  },
  mounted() {
    this.getNfaShortClose();
  },
  methods: {
    saveNfaShortClose() {
      this.addEditNfaShortClose();
    },
    getUserName() {
      const tempName = store.state.auth.userName;
      const name = tempName.slice(tempName.lastIndexOf('-') + 1);
      return name;
    },
    getNfaShortClose() {
      const payload = {
        nfa_hdr_id: this.nfaHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getNfaShortClose', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.closedBy.value = results.closed_by;
            this.shortCloseDate = results.closing_date;
            this.reason = results.closing_reason;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditNfaShortClose() {
      const payload = {
        closing_date: this.shortCloseDate,
        closing_reason: this.reason,
        nfa_hdr_id: this.nfaHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/addEditNfaShortClose', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    }
  }
};
