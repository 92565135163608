import commonHelper from '@/app/utility/common.helper.utility';
import addNfaApproval from './addNfaApproval';
import DatePicker from 'vue2-datepicker';
import User from '../../admin/user';
import store from '../../../../store';
import shortClose from '../nfaApproval/shortClose';
import PrintNfaApproval from '../nfaApproval/addNfaApproval/printNfaApproval';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';
import downlaodFiles from '../nfaApproval/addNfaApproval/downloadFiles';
import URL_UTILITY from '@/app/utility/url.utility.js';
import uploadExcel from '@/app/components/shared/uploadExcel';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'nfaApproval',
  components: {
    DatePicker,
    addNfaApproval,
    shortClose,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    sendForApproval,
    openKmAuthentication,
    PrintNfaApproval,
    User,
    downlaodFiles,
    uploadExcel
  },
  watch: {
    currentPage: function() {
      this.getList();
    },
    // currentUploadPage: function() {
    //   this.getUploadedDocDtl();
    // },
    downloadCurrentPage: function() {
      this.getUploadedDocDtl();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getList();
    },
    // perUploadPage: function() {
    //   this.currentUploadPage = 1;
    //   this.getUploadedDocDtl();
    // },
    downloadPerPage: function() {
      this.downloadCurrentPage = 1;
      this.getUploadedDocDtl();
    },
    inventorycurrentPage: function() {
      this.getItemMasterDetails();
    },
    inventoryperPage: function() {
      this.inventorycurrentPage = 1;
      this.getItemMasterDetails();
    }
  },
  data() {
    return {
      loader: false,
      showChildModal: false,
      unsubscribe: null,
      selectedNfaDetails: false,
      sourceApprovalObj: false,
      selectedGridData: null,
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      totalUploadRows: null,
      // currentUploadPage: 1,
      // perUploadPage: commonHelper.perPageRecord,
      downloadCurrentPage: 1,
      downloadPerPage: commonHelper.perPageRecord,
      inventorycurrentPage: 1,
      inventoryperPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      nfaNumber: null,
      invCode: null,
      inventory_code: null,
      inventory_item_id: null,
      description: null,
      quantity_uom: null,
      creatorName: {
        value: null,
        text: this.getUserName()
      },
      nfaStatus: {
        value: null,
        text: null
      },
      fromDate: null,
      toDate: commonHelper.formattedDate(new Date()),
      // toDate: new Date(),
      // fromDate: this.preMonthDate(this.toDate),
      buyName: null,
      nfaHdrId: null,
      showHistoryBtn: false,
      showHistory: false,
      historyType: null,
      desc: null,
      showPrintModal: false,
      showOpenKmModal: false,
      openkmAuthToken: null,
      showItemModal: false,
      showValueSetModal: false,
      showUserModal: false,
      data: [],
      fields: [
        {
          key: 'initiate_approval',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary',
          class: 'stickyColumn'
        },
        {
          key: 'nfa_number',
          label: 'NFA Number'
        },
        {
          key: 'nfa_objective',
          label: 'NFA Title'
        },
        {
          key: 'created_by',
          label: 'Creator Name'
        },
        {
          key: 'approval_status',
          label: 'NFA Status'
        },
        {
          key: 'nfa_validity_status',
          label: 'NFA Validity'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'end_date',
          label: 'Effective Date'
        }
      ],
      searchItemData: [],
      searchItemFields: [
        {
          key: 'item_code'
        },
        {
          key: 'description'
        },
        {
          key: 'quantity_uom',
          label: 'Quantity UOM'
        }
      ],
      copyNfaHdrId: null,
      itemData: [],
      showShortCloseModal: false,
      createCopyWorkflow: false,
      downloadItems: [],
      downloadFields: [
        {
          key: 'original_file_name',
          label: 'File Name'
        },
        {
          key: 'doc_version',
          label: 'Version'
        },
        {
          key: 'file_size',
          label: 'Size'
        },
        {
          key: 'comments'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        }
      ],
      showDownloadModal: false,
      modalType: null,
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      showUploadExcel: false
    };
  },
  mounted() {
    this.dateForInventory();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showChildModal = true;
          this.nfaHdrId = null;
          this.createCopyWorkflow = false;
        }
      }
    });
  },
  validations: {
    toDate: { required },
    fromDate: { required }
  },
  methods: {
    getUserName() {
      const tempName = store.state.auth.userName;
      const name = tempName.slice(tempName.lastIndexOf('-') + 1);
      return name;
    },
    getList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          nfa_number: this.nfaNumber,
          approval_status: this.nfaStatus.value,
          created_by: this.creatorName.text,
          inv_item_id: this.inventory_item_id,
          creation_date_from: this.fromDate,
          creation_date_to: this.toDate
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/getNfaGridList', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.data = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getItemMasterDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        inventory_code: this.invCode,
        description: this.desc
      };
      this.loader = true;
      this.$store
        .dispatch('invAdmin/getItemMasterDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data.data.page;
            this.searchItemData = results;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      this.selectedNfaDetails = item;
      this.nfaHdrId = item.nfa_hdr_id;
      this.createCopyWorkflow = false;
      this.showHideNfaApprovalModal(true);
    },
    copyWorkflow() {
      const promt = confirm('Are you sure, you want to create copy?');
      if (promt) {
        this.createCopyWorkflow = true;
        this.showHideNfaApprovalModal(true);
        // this.getNfaDetails(this.selectedNfaDetails.nfa_hdr_id);
      }
    },
    showHideNfaApprovalModal(flag) {
      this.showChildModal = flag;
    },
    selectedApproval(item) {
      // to select payment
      this.selectedNfaDetails = item;
      this.sourceApprovalObj = item;
      this.nfaHdrId = item.nfa_hdr_id;
      this.showHistoryBtn = true;
    },
    requestGenerate() {},
    showHideHistory(flag, historyType) {
      // to click initiate approval than visible button
      if (
        (this.selectedNfaDetails.approval_status === 'INPROCESS' ||
          this.selectedNfaDetails.approval_status === 'NOTREQUIRED' ||
          this.selectedNfaDetails.approval_status === 'APPROVED') &&
        historyType === 'uploadDocument'
      ) {
        alert('You Cannot Upload Document in this Stage');
      } else {
        this.showHistory = flag;
        this.historyType = historyType;
      }
    },
    checkOpenKmPasswordSaved(modalType) {
      // to check password save or not
      this.modalType = modalType;
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      // to get auth token for open km
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            if (this.modalType === 'DOWNLOAD') {
              this.downloadFiles(true);
            } else {
              this.selectedGridData = {
                ...this.selectedNfaDetails,
                nfa_cat_vset: null,
                nfa_cat: null,
                nfa_sub_cat_vset: null,
                nfa_sub_cat: null
              };
              this.showHideHistory(true, 'uploadDocument');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      // to open km modal
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      // to set token in km
      this.showHideOpenKmModal(false);
      if (this.modalType === 'DOWNLOAD') {
        this.downloadFiles(true);
      } else {
        this.openkmAuthToken = token;
        this.selectedGridData = {
          ...this.selectedNfaDetails,
          nfa_cat_vset: null,
          nfa_cat: null,
          nfa_sub_cat_vset: null,
          nfa_sub_cat: null
        };
        this.showHideHistory(true, 'uploadDocument');
      }
    },
    closeValueSetModal() {
      // To close valueset modal
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode) {
      // To open valueset modal
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      // To select valueset
      if (this.vsetCode === 'APPROVAL_STATUS') {
        this.nfaStatus.value = item.value_code;
        this.nfaStatus.text = item.value_meaning;
      } else if (this.vsetCode === 'INV_ITEM_CODE') {
        this.inventory_item_id = item.value_set_dtl_id;
        this.inventory_code = item.value_code;
        this.description = item.description;
        this.quantity_uom = item.primary_uom_code;
      }
      this.showValueSetModal = false;
    },
    openCloseModal(flag) {
      this.showItemModal = flag;
    },
    selectedItem(item) {
      this.inventory_item_id = item.inventory_item_id;
      this.inventory_code = item.description;
      this.description = item.description;
      this.quantity_uom = item.quantity_uom;
      this.showItemModal = false;
    },
    showHidePrint(flag) {
      this.showPrintModal = flag;
    },
    async printIntWorkflow() {
      await this.$htmlToPaper('print-workflow', {
        windowTitle: `DPL SA ${new Date().toString().slice(0, 23)}`
      });
    },
    showHideUserModal(flag) {
      this.showUserModal = flag;
    },
    selectedUser(item) {
      this.creatorName.text = item.name;
      this.showUserModal = false;
    },
    resetSearchFilters() {
      this.nfaNumber = null;
      this.creatorName = {
        value: null,
        text: null
      };
      this.nfaStatus = {
        value: null,
        text: null
      };
      this.fromDate = null;
      this.toDate = null;
      this.buyName = null;
      this.data = [];
      this.showHistoryBtn = false;
      this.totalRows = null;
      this.inventory_code = null;
      this.inventory_item_id = null;
      this.currentPage = 1;
    },
    getNfaHdrId(item) {
      this.nfaHdrId = item.nfa_hdr_id;
      this.selectedNfaDetails = item;
      // this.getList();
    },
    showHideShortClose(flag) {
      this.showShortCloseModal = flag;
    },
    downloadFiles(flag) {
      this.showDownloadModal = flag;
      this.getUploadedDocDtl();
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.downloadCurrentPage - 1,
        _limit: this.downloadPerPage,
        menu_id: store.state.shared.menuId
          ? store.state.shared.menuId
          : this.urlSummaryData.wf_menu_id,
        sub_menu_id: 0,
        record_id: this.nfaHdrId
          ? this.nfaHdrId
          : this.urlSummaryData.nfa_hdr_id
      };
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page;
              this.downloadItems = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
            }
            this.totalUploadRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    comparisonCase() {
      if (/valid/i.test(this.selectedNfaDetails.nfa_validity_status)) {
        return true;
      }
      return false;
    },
    showHideUploadExcel(flag) {
      this.showUploadExcel = flag;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'APPROVAL_STATUS') {
        this.nfaStatus.value = null;
        this.nfaStatus.text = null;
      }
    },
    dateForInventory() {
      let today = new Date();
      let prevMonth = this.preMonthDate(today);
      this.fromDate = commonHelper.formattedDate(prevMonth);
    },
    preMonthDate(date) {
      let prevDate = new Date(
        date.getFullYear(),
        date.getMonth() - 3,
        date.getDate() + 1
      );
      return prevDate;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
